import React from 'react';

export const CustomInput = ({ label, name, type, required, defaultValue }) => {
    return (
        <div>
            <label className="mb-2">{label}:</label>
            <input
                placeholder={`Enter ${label}`}
                className="form-control mb-2"
                name={name}
                type={type}
                required={required}
                defaultValue={defaultValue}
            />
        </div>
    )

}

CustomInput.defaultProps = {
    label: "",
    name: "",
    type: "text",
    required: true,
    defaultValue: "",
};

export default CustomInput;

