import React from "react";
import Modal from "react-bootstrap/Modal";
import { useRecoilState } from "recoil";
import { isLoaderState, snakeBarState } from "../../recoil/RecoilStore";
import { apiPost } from "../../util/ApiRequest";
import { ENDPOINTS } from "../../util/EndPoint";
import { CustomInput } from "../global/CustomInput";
import CustomInputFile from "../global/CustomInputFile";
import CustomNumberInput from "../global/CustomNumberInput";
import CustomTextArea from "../global/CustomTextArea";


const CreateBadge = ({ showModalPro, setModalHidePro, refreshDataPro }) => {
    const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
    const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);
    const handleSubmit = (event) => {
        setIsLoaderInfo(true);
        event.preventDefault();
        const body = new FormData(event.target);
        apiPost(
            ENDPOINTS.BadgesCreate,
            body,
            (res) => {
                setModalHidePro();
                refreshDataPro();
                setSnackBarInfo({
                    snackStatus: true,
                    snackColor: "bg-success",
                    snackMsg: "Successful",
                });
                setIsLoaderInfo(false);
            },
            (error) => {
                setIsLoaderInfo(false);
                setModalHidePro();
                refreshDataPro();
                setSnackBarInfo({
                    snackStatus: true,
                    snackColor: "bg-danger",
                    snackMsg: "There is an Error Plz Try Again ",
                });
            }
        );
    };

    return (
        <>

            <Modal show={showModalPro} onHide={() => setModalHidePro()}>
                <Modal.Header
                    closeButton
                    className="bg-primary text-white custom-modal-header"
                >
                    <Modal.Title className="font-18">Create Badge</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <CustomInput label={"Name"} name={"name"} type={"text"} required={true} />
                        <CustomNumberInput label={"price"} name={"price"}  required={true} />
                        <CustomTextArea label={"Description"} name={"description"} required={true} />
                        <div className="mt-2">
                        <CustomInputFile name={'media'} label={"File"} required={true} />
                        </div>

                        <input className="btn btn-secondary mt-3" type="submit" />
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CreateBadge;
