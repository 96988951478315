import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useRecoilState } from "recoil";
import { isLoaderState, snakeBarState } from "../../recoil/RecoilStore";
import { apiPost } from "../../util/ApiRequest";
import { ENDPOINTS } from "../../util/EndPoint";

const UpdatePhoneNumber = ({
  showModalPro,
  setModalHidePro,
  refreshDataPro,
  item,
}) => {
  const [value, setValue] = useState(item?.phone_number);

  useEffect(() => {
    if (item?.phone_number) {
      setValue(item.phone_number);
    }
  }, [item]);
  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);
  const handleSubmit = (event) => {
    setIsLoaderInfo(true);
    event.preventDefault();
    apiPost(
      ENDPOINTS.ChangeUserNumber,
      {
        user_id: item?.id,
        phone_number: `+${value}`,
      },
      (res) => {
        setModalHidePro();
        refreshDataPro();
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "Successful",
        });
        setIsLoaderInfo(false);
      },
      (error) => {
        setIsLoaderInfo(false);
        setModalHidePro();
        refreshDataPro();
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: "There is an Error Plz Try Again ",
        });
      }
    );
  };

  return (
    <>
      <Modal show={showModalPro} onHide={() => setModalHidePro()}>
        <Modal.Header
          closeButton
          className="bg-primary text-white custom-modal-header"
        >
          <Modal.Title className="font-18">Update phone number</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-2">
              <PhoneInput
                country={"us"}
                value={item?.phone_number}
                onChange={(phone) => setValue(phone)}
                inputProps={{ name: "phone_number" }}
              />
            </div>

            <div className="text-end">
              <button
                type="submit"
                className="btn btn-primary font-12 text-white"
              >
                Update
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdatePhoneNumber;
