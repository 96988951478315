import React from "react";
import { useNavigate } from "react-router-dom";
import SingleImage from "../components/global/SingleImage";
import TableComponent from "../components/global/TableComponent";
import { UserAvatar } from "../components/global/UserAvatar";
import { ENDPOINTS } from "../util/EndPoint";

const Reporting = () => {
  const navigate = useNavigate();

  const columns = [
    {
      name: "Issue",
      selector: (row) => row.message,
      sortable: false,
    },
    {
      name: "Reported by",
      selector: (row) => (
        <div className="m-2">
          {" "}
          <UserAvatar user={row.reported_by} />
        </div>
      ),
      sortable: false,
    },

    {
      name: "Reported to",
      selector: (row) => (
        <div className="m-2">
          {" "}
          <UserAvatar user={row.reported_to} />{" "}
        </div>
      ),
      sortable: false,
    },
    {
      name: "Image",
      selector: (row) => (
        <div>
          {row?.media && (
            <SingleImage
              style={{
                width: 40,
                height: 40,
                margin: 6,
                border: "1px solid #000",
              }}
              image={row?.media}
            />
          )}
        </div>
      ),
      sortable: false,
    },
  ];

  return (
    <>
      <TableComponent
        title="Reporting"
        endPoint={ENDPOINTS.ReportingPaginated}
        headers={columns}
        // searchObject={ }
      />
    </>
  );
};
export default Reporting;
