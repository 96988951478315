import React from 'react';

import Crisis from '../../assets/images/gifs/crisis.gif';
import Good from '../../assets/images/gifs/good.gif';
import Happy from '../../assets/images/gifs/happy.gif';
import Ok from '../../assets/images/gifs/ok.gif';
import Struggling from '../../assets/images/gifs/struggling.gif';
import { dateMonthYear, dateTime } from '../../util/Helper';


const MoodItem = ({ currentMood }) => {
    let moodImage;

    switch (currentMood?.mood) {
        case 'crisis':
            moodImage = Crisis;
            break;
        case 'good':
            moodImage = Good;
            break;
        case 'happy':
            moodImage = Happy;
            break;
        case 'ok':
            moodImage = Ok;
            break;
        case 'struggling':
            moodImage = Struggling;
            break;
        default:
            moodImage = null;
    }



    return (
        <div className='current-mood-item bg-primary px-3 py-3 rounded-2'>
            <div className='d-flex gap-1'>
                <img className='align-self-center' src={moodImage} alt={moodImage} />
            </div>

            <h4 className='align-self-center mb-0'>{currentMood?.mood}</h4>

            <div className='text-end'>
                <h6 className='mb-1 font-12'>{dateMonthYear(currentMood.created_at)}</h6>
                <h6 className='mb-0 font-12'>{dateTime(currentMood.created_at)}</h6>
                <h6 className='mb-0 font-12'> Week {currentMood?.week_name}</h6>
            </div>


        </div>
    )
}

export default MoodItem