import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Image, Modal, Table } from "react-bootstrap";
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from "recoil";
import CreateAdds from "../components/forms/CreateAdds";
import DeleteItemModal from "../components/forms/DeleteItemModal";
import UpdateAdds from "../components/forms/UpdateAdds";
import CustomTooltip from "../components/global/CustomTooltip";
import TableComponent from "../components/global/TableComponent";
import { isLoaderState, snakeBarState } from "../recoil/RecoilStore";
import { apiPost } from "../util/ApiRequest";
import { BASE_IMG_URL, ENDPOINTS } from "../util/EndPoint";

const Adds = () => {

  const playerRef = useRef(null);

  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.seekTo(0); 
        playerRef.current.getInternalPlayer().pause();
      }
    };
  }, []);


  const [detailModal, setDetailModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  
  const [selectItem, setSelectItem] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [reloadData, setReloadData] = useState(false);

  //recoil
  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);



  const toggleHandle = (row) => {
    apiPost(
      ENDPOINTS.AddsActiveDeActive,
      {
        add_id: row?.id,
        location: row?.location
      },
      (res) => {

        setReloadData(!reloadData)
        setIsLoaderInfo(false);
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "Successful changed",
        });
      },
      (error) => {
        console.log(error);
        setIsLoaderInfo(false);
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: "There is an Error Plz Try Again ",
        });

      }

    );

  };


  const navigate = useNavigate();

  const columns = [

    {
      name: 'Id',
      selector: (row) => row?.id,
      sortable: false,
    },
    {
      name: 'Name',
      selector: (row) => row?.name,
      sortable: false,
    },

    {
      name: 'Company',
      selector: (row) => row?.company,
      sortable: false,
    },
    {
      name: 'Price',
      selector: (row) => row?.price,
      sortable: false,
    },

    {
      name: 'Url',
      selector: (row) => row?.url,
      sortable: false,
    },


    {
      name: 'Start Date',
      selector: (row) => moment.utc(row?.start_date_time).format('DD-MMM-YYYY hh:mm a'),
      sortable: false,
      maxWidth: '400px',
      minWidth: '250px'
    },


    {
      name: 'End Date',
      selector: (row) => moment.utc(row?.end_date_time).format('DD-MMM-YYYY hh:mm a'),
      sortable: false,
      maxWidth: '400px',
      minWidth: '250px'
    },


    {
      name: 'Placements',
      selector: (row) => row?.location,
      sortable: false,
      wrap: true,
      maxWidth: '400px',
      minWidth: '250px'
    },


    {
      name: 'Description',
      selector: (row) => <CustomTooltip descriptionPro={row?.description} />,
      sortable: false,
      wrap: true,
      maxWidth: '400px',
      minWidth: '250px'
    },

    {
      name: 'Status',
      selector: (row) =>
        <Form.Check
          type="switch"
          checked={row?.is_active == 1 ? true : false}
          id="custom-switch"
          onChange={() => {
            toggleHandle(row)
          }}

          label={row?.is_active == 1 ? 'Active' : 'Deactive'}
        />
      ,
      sortable: false,
      wrap: true,
      maxWidth: '400px',
      minWidth: '250px'
    },
    {
      name: 'Update',
      selector: (row) => <>
        <button onClick={() => {
          setSelectItem(row)
          setUpdateModal(true)
        }} className="btn btn-primary text-white font-12">Update

        </button></>,
      width: '160px'

    },
    {
      name: 'Detail',
      selector: (row) => <>
        <button onClick={() => {
          setSelectItem(row)
          setDetailModal(true)
        }} className="btn btn-primary text-white font-12">Detail

        </button></>,
      width: '160px'

    },

    {
      name: 'Delete',
      selector: (row) => <>
        <button onClick={() => {
          setSelectItem(row)
          setDeleteModal(true)
        }} className="btn btn-danger text-white font-12">Delete

        </button></>,
      width: '160px'

    },


  ];



  return (
    <>

      <Modal show={detailModal} onHide={() => setDetailModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body> <div className="">

          <Table striped bordered hover>

            <tbody>
              <tr>
                <th>Name</th>
                <td>{selectItem?.name}</td>
              </tr>

              <tr>
                <th>Company</th>
                <td>{selectItem?.company}</td>
              </tr>

              <tr>
                <th>Start date</th>
                <td>{moment.utc(selectItem?.start_date_time).format('DD-MMM-YYYY hh:mm a')}</td>
              </tr>

              <tr>
                <th>End date</th>
                <td>{moment.utc(selectItem?.end_date_time).format('DD-MMM-YYYY hh:mm a')}</td>
              </tr>

              <tr>
                <th>Active</th>
                <td>{selectItem?.is_active == 1 ? 'Yes' : 'No'}</td>
              </tr>

              <tr>
                <th>Price</th>
                <td>{selectItem?.price}</td>
              </tr>


              <tr>
                <th>Placement</th>
                <td>{selectItem?.location}</td>
              </tr>

              <tr>
                <th>Url</th>
                <td>{selectItem?.url}</td>
              </tr>

              <tr>
                <th>Description</th>
                <td>{selectItem?.description}</td>
              </tr>








            </tbody>
          </Table>


          <div className=" text-center">
            {selectItem?.file_type == 'image' && (
              <>
                {selectItem?.media != null && (
                  <Image className="image-detail-add" src={BASE_IMG_URL + selectItem?.media} thumbnail />
                )}

              </>
            )}
            {selectItem?.file_type == 'video' && (
              <>
                {selectItem?.media != null && (
                   <ReactPlayer
      
                   ref={playerRef}
             
                   className="video-player-custom" controls={true}     playing={true} url={BASE_IMG_URL + selectItem?.media} />
                )}

              </>
            )}
          </div>



        </div>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDetailModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal >


      <CreateAdds
        showModalPro={showModal}
        setModalHidePro={() => setShowModal(false)}
        refreshDataPro={() => {
          setShowModal(false)
          setReloadData(!reloadData)
        }} />

      <UpdateAdds
        showModalPro={updateModal}
        selectedItem={selectItem}
        setModalHidePro={() => setUpdateModal(false)}
        refreshDataPro={() => {
          setUpdateModal(false)
          setReloadData(!reloadData)
        }} />



      <DeleteItemModal
        url={ENDPOINTS.AddsDelete}
        body={{
          add_id: selectItem.id
        }}
        reloadData={() => {
          setDeleteModal(false)
          setReloadData(!reloadData)
        }}


        show={deleteModal}
        hideModal={() => setDeleteModal(false)}
        text={`Do you want to delete ${selectItem.name} ads?`}
        title="Delete Ad's" />



      <div className="text-end mb-2">
        <button onClick={() => setShowModal(true)} className="btn btn-primary font-12 " >Create Adds</button>
      </div>

      <TableComponent
        title="Adds"
        endPoint={ENDPOINTS.AddsPaginated}
        headers={columns}
        // searchObject={ }
        reloadData={reloadData}


      />
    </>
  )
}
export default Adds;
