import moment from "moment";
import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import CurrentUserMood from "./CurrentUserMood";



const UserOverView = ({ userObjPro }) => {
  return (


    <div className='mt-4'>

      <Row>
        <Col md="6">
          <h4 className='user-info-heading bg-primary  text-secondary'>User Info</h4>
          <Table striped bordered hover className='user-detail-info-table'>
            <tbody>
              <tr>
                <th>Account Created</th>
                <td>{moment(userObjPro?.created_at).format("LL")}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{userObjPro?.email}</td>
              </tr>

              <tr>
                <th>Full Name</th>
                <td>{userObjPro?.first_name} {userObjPro?.last_name}</td>
              </tr>


              <tr>
                <th>Status</th>
                <td>{userObjPro?.status == 1 ? 'Online' : 'Offline'}</td>
              </tr>

              <tr>
                <th>Account deleted</th>
                <td>{userObjPro?.is_deleted == 1 ? 'Yes' : 'No'}</td>
              </tr>


              <tr>
                <th>Block Adds</th>
                <td>{userObjPro?.is_add_block == 1 ? 'Yes' : 'No'}</td>
              </tr>




              <tr>
                <th>Public profile</th>
                <td>{userObjPro?.is_public == 1 ? 'Yes' : 'No'}</td>
              </tr>

              <tr>
                <th>Gender</th>
                <td>{userObjPro?.gender}</td>
              </tr>

              <tr>
                <th>Mobile</th>
                <td>{userObjPro?.phone_number}</td>
              </tr>
              <tr>
                <th>Date of birth</th>
                <td>{userObjPro?.dob}</td>
              </tr>


            </tbody>
          </Table>
        </Col>
        <Col md="6">


          <div className="mb-2 card">
            <h4 className='user-info-heading bg-primary text-secondary'>Current mood {userObjPro?.current_mood?.mood}</h4>
            <CurrentUserMood currentMood={userObjPro?.current_mood} />
          </div>
        </Col>
      </Row>


    </div>
  )
}

export default UserOverView