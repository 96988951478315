import React from 'react';
import Select from 'react-select';



const PlacementSelect = ({ name, label,defaultValue }) => {
    let findIndex =0;
    const items = [
        { value: 'home', label: 'Home' },
        { value: 'friend', label: 'Friend' },
        { value: 'other', label: 'Other' },
    ]

    if(defaultValue){
     findIndex =items.findIndex(x=>x.value ===defaultValue)

    }
    return (
        <div>
            <label className="mb-2">{label}:</label>
            
            <Select
                className='select-custom'
                isMulti={false}
                options={items}
                name={name}
                defaultValue={[items[findIndex]]}


            />

        </div>
    )
}

PlacementSelect.defaultProps = {
    defaultValue: ''
};

export default PlacementSelect