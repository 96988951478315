import React, { useState } from "react";
import { CiEdit, CiTrash } from "react-icons/ci";
import CreateFaqs from "../components/forms/CreateFaqs";
import DeleteItemModal from "../components/forms/DeleteItemModal";
import DetailFaqs from "../components/forms/DetailFaqs";
import UpdateFaqs from "../components/forms/UpdateFaqs";
import TableComponent from "../components/global/TableComponent";
import { ENDPOINTS } from "../util/EndPoint";


import { FaEye } from "react-icons/fa";

const Faqs = () => {
  const [reloadData, setReloadData] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectItem, setSelectItem] = useState({});



  const columns = [
    {
      name: 'Question',
      selector: (row) => row.question,
      sortable: false,
      width: '300px'
    },
    {
      name: 'Answer',
      selector: (row) => row.answer,
      sortable: false,
      width: '500px'

    },

    {
      name: 'Update',
      selector: (row) => <>
        <button onClick={() => {
          setSelectItem(row)
          setUpdateModal(true)
        }} className="btn btn-primary"><CiEdit />
        </button></>,
      width: '100px'

    },

    {
      name: 'Detail',
      selector: (row) => <>
        <button onClick={() => {
          setSelectItem(row)
          setDetailModal(true)
        }} className="btn btn-primary"><FaEye />

        </button></>,
      width: '100px'

    },
    {
      name: 'Delete',
      selector: (row) => <>
        <button onClick={() => {
          setSelectItem(row)
          setDeleteModal(true)
        }} className="btn btn-danger"><CiTrash />

        </button></>,
      width: '100px'

    },
  ];


  return (
    <>

      <div className=" mb-2 text-end">
        <button className="btn btn-primary" onClick={() => setCreateModal(true)}>Add Faqs</button>
      </div>

      <TableComponent
        title="Faqs"
        endPoint={ENDPOINTS.FaqsPaginated}
        headers={columns}
        reloadData={reloadData}
      // searchObject={ }
      />


      <CreateFaqs
        setModalHidePro={() => setCreateModal(false)}
        showModalPro={createModal}
        refreshDataPro={() => setReloadData(!reloadData)}
      />
      <UpdateFaqs
        selectedItem={selectItem}
        setModalHidePro={() => setUpdateModal(false)}
        showModalPro={updateModal}
        refreshDataPro={() => setReloadData(!reloadData)}
      />
      <DetailFaqs
        selectedItem={selectItem}
        setModalHidePro={() => setDetailModal(false)}
        showModalPro={detailModal}
      />

      <DeleteItemModal
        url={ENDPOINTS.FaqDelete}
        body={{
          faq_id: selectItem.id
        }}
        reloadData={() => {
          setDeleteModal(false)
          setReloadData(!reloadData)
        }}


        show={deleteModal}
        hideModal={() => setDeleteModal(false)}
        text={`Do you want to delete ${selectItem.question} question?`}
        title="Delete Question" />
    </>
  )
}
export default Faqs;
