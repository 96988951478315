import React from "react";
import { useNavigate } from 'react-router-dom';
import TableComponent from "../components/global/TableComponent";
import { ENDPOINTS } from "../util/EndPoint";

const ContactUs = () => {
  const navigate = useNavigate();

  const columns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: false,
      width: '60px'
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: false,
    },
    {
      name: 'Subject',
      selector: (row) => row.subject,
      sortable: false,
    },
    {
      name: 'Message',
      selector: (row) => row.message,
      sortable: false,
    },
  ];


  return (
    <>

      <TableComponent
        title="Contact us"
        endPoint={ENDPOINTS.EmailPaginated}
        headers={columns}
      // searchObject={ }

      />
    </>
  )
}
export default ContactUs;
