import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { GoVerified } from "react-icons/go";
import { useRecoilState } from 'recoil';
import CatImg from "../assets/images/user.jpg";
import "../assets/pages-css/user-detail.css";
import MoodHistory from '../components/global/MoodHistory';
import UserOverView from '../components/global/UserOverView';
import { isLoaderState } from '../recoil/RecoilStore';
import { apiGetAuth } from '../util/ApiRequest';
import { BASE_IMG_URL, ENDPOINTS } from '../util/EndPoint';

const UsersDetail = ({ }) => {
  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [userObj, setUserObj] = useState({});
  useEffect(() => {
    let userId = /[^/]*$/.exec(window.location.href)[0];
    getUserInfo(userId);
  }, []);

  const getUserInfo = (userId) => {
    setIsLoaderInfo(true);
    apiGetAuth(
      ENDPOINTS.UserDetails + userId,
      (res) => {
        setUserObj(res);
        setIsLoaderInfo(false);
      },
      (error) => {
        console.log(error, ' error');
        setIsLoaderInfo(false);
      },
    );
  };

  const OrderHeader = [
    { name: "Barber", key: "barber", type: "barber_card", path: "" },
    { name: "Total amount", key: "total_amount", type: "text", path: "" },
    { name: "Book time", key: "book_time", type: "date", path: "" },
    { name: "Book complete", key: "book_time", type: "date", path: "" },
    { name: "Status", key: "order_status", type: "order_status", path: "" },
    { name: "Tip", key: "order_tip", type: "text", path: "" },
    { name: "Created", key: "created_at", type: "date", path: "" },
    { name: "Booked date time", key: "client_booked_date_time", type: "date", path: "" },
    { name: "Details", key: "id", type: "href", path: "/admin/order-detail/" },

  ];


  const BarberRatingHeader = [
    { name: "Rating", key: "rate_number", type: "text", path: "" },
    { name: "Barber", key: "rating_to", type: "barber_card", path: "" },
    { name: "Order detail", key: "order_id", type: "href", path: "/admin/order-detail/" },

  ];


  return (

    <div className=' user-profile-main'>
      <div className='user-profile'>
        <div className='user-image-box'>
          <img src={userObj?.profile_picture ? BASE_IMG_URL + userObj?.profile_picture : CatImg} alt="profile image" className='user-image' />
        </div>

        <div className='user-info-box my-auto'>
          <div className='user-info-box-personal'>
            <h3>{userObj?.first_name} {userObj?.last_name}<span className='font-icon-14 text-success'><GoVerified /></span></h3>
            <div className='info-icon'>
              <p className='d-block'><span className='font-icon-12'><FaEnvelope /></span>  {userObj?.email}</p>
              <p className='d-block'> <span className='font-icon-12'><FaPhoneAlt /></span> {userObj?.phone_number}</p>
            </div>
          </div>
        </div>




      </div>
      <div className='mt-4  user-profile-nav-tabs'>
        <Tabs
          defaultActiveKey="overview"
          className="mb-3"
        >
          <Tab eventKey="overview" title="Overview">
            {userObj && (
              <UserOverView userObjPro={userObj} />
            )}
          </Tab>
          <Tab eventKey="history_mood" title="History mood">
            {userObj?.id && (
              <MoodHistory user={userObj} />
            )}
          </Tab>
          <Tab eventKey="rating" title="Rating">
            {/* {userObj?.id && (
              <PaginatedData
                title="Rating"
                endPoint={ENDPOINTS.ClientRating}
                headers={BarberRatingHeader}
                searchObject={{ user_id: userObj?.id }}
              />
            )} */}
          </Tab>

        </Tabs>
      </div>
    </div >

  )
}

export default UsersDetail