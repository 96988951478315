import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';
import Modal from "react-bootstrap/Modal";
import { useRecoilState } from "recoil";
import { isLoaderState, snakeBarState } from "../../recoil/RecoilStore";
import { apiPost } from "../../util/ApiRequest";
import { ENDPOINTS } from "../../util/EndPoint";
import CustomDateTimeInput from "../global/CustomDateTimeInput";
import CustomInput from "../global/CustomInput";
import CustomInputFile from "../global/CustomInputFile";
import PlacementSelect from "../global/PlacementSelect";


const CreateAdds = ({ showModalPro, setModalHidePro, refreshDataPro }) => {
    const [existsItem, setExistsItem] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
    const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);

    
    const handleSubmit = (event) => {
        setIsLoaderInfo(true);
        event.preventDefault();
        const stopTime = document.getElementsByName('end_date_time')[0].value;
        const startTime = document.getElementsByName('start_date_time')[0].value;
  
        if (stopTime < startTime) {
          setSnackBarInfo({
            snackStatus: true,
            snackColor: "bg-danger",
            snackMsg: "Stop time must be greater than start time",
          });
        } else {
    
        const body = new FormData(event.target);
        apiPost(
            ENDPOINTS.AddsAdd,
            body,
            (res) => {

                if (res?.data.is_exists == false) {
                    setModalHidePro();
                    refreshDataPro();
                    setSnackBarInfo({
                        snackStatus: true,
                        snackColor: "bg-success",
                        snackMsg: "Successful",
                    });
                }
                if (res?.data.is_exists == true) {
                    setShowAlert(true)
                    setExistsItem(res?.data)
                }
                setIsLoaderInfo(false);
            },
            (error) => {
                console.log(error);
                setIsLoaderInfo(false);
                setModalHidePro();
                refreshDataPro();
                setSnackBarInfo({
                    snackStatus: true,
                    snackColor: "bg-danger",
                    snackMsg: "There is an Error Plz Try Again ",
                });
            }
        );
    }
    };

    return (
        <>

            <Modal show={showModalPro} onHide={() => setModalHidePro()}>
                <Modal.Header
                    closeButton
                    className="bg-primary text-white custom-modal-header"
                >
                    <Modal.Title className="font-18">Create Add</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <CustomInput label={"Name"} name={"name"} type={"text"} required={true} />
                        <CustomInput label={"Company"} name={"company"} type={"text"} required={true} />
                        <CustomInput label={"Price"} name={"price"} type={"number"} required={true} />
                        <CustomInput label={"Url"} name={"url"} type={"text"} required={true} />

                        <CustomDateTimeInput
                            placeholder={'select start date'}
                            label={"Start date"}
                            name={"start_date_time"}
                            required={true}
                        />
                        <div className="mt-2">
                            <CustomDateTimeInput
                                placeholder={'select emd date'}
                                label={"End date"}
                                name={"end_date_time"}
                                required={true}
                            />
                        </div>
                        <div className="mt-2">
                            <PlacementSelect name={'location'} label={"Placement"} />
                        </div>
                        <div className="mb-3 mt-3">
                            <CustomInputFile name={'media'} label={"File"} />
                        </div>

                        <div className="mb-3 mt-3"> 
      <label className="mb-2">{'Description'}</label>

      <Form.Control
          as="textarea"
          placeholder="Description"
          name={'description'}
        />
  

    </div>

        
                        <input className="btn btn-secondary mt-3" type="submit" />
                    </form>

                    <Alert variant="danger" show={showAlert}  className="mt-2" onClose={() => setShowAlert(false)} dismissible>
                        <Alert.Heading>Date Conflict</Alert.Heading>
                        <p>
                        The date is conflicting with ID # {existsItem?.id}, so you need to change the date to update the record associated with the selected date                        </p>
                    </Alert>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CreateAdds;
