import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import UpdatePhoneNumber from "../components/forms/UpdatePhoneNumber";
import DeleteItemModal from "../components/global/DeleteItemModal/DeleteItemModal";
import TableComponent from "../components/global/TableComponent";
import { isLoaderState } from "../recoil/RecoilStore";
import { apiPost } from "../util/ApiRequest";
import { ENDPOINTS } from "../util/EndPoint";

const Users = () => {
  const [reloadData, setReloadData] = useState(false);
  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);

  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const navigate = useNavigate();

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: false,
      width: "60px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: false,
    },
    {
      name: "First name",
      selector: (row) => row.first_name,
      sortable: false,
    },
    {
      name: "Last name",
      selector: (row) => row.last_name,
      sortable: false,
    },
    {
      name: "Phone number",
      selector: (row) => (
        <div className="d-flex gap-1">
          <p className="my-auto">{row.phone_number}</p>
          <button
            onClick={() => {
              setUpdateModal(true);
              setSelectedItem(row);
            }}
            className="ms-1 btn btn-primary font-12 btn-no-pad"
          >
            <FaEdit />
          </button>
        </div>
      ),
      sortable: false,
      width: "180px",
    },

    {
      name: "Account status",
      selector: (row) => (
        <button
          onClick={() => UserActiveDeActiveHandle(row?.id)}
          className="btn btn-danger"
        >
          {row?.is_active == 1 ? "Deactive" : "Active"}
        </button>
      ),
      sortable: false,
    },
    {
      name: "Delete Account",
      selector: (row) => (
        <p className="mb-1">
          {row.is_deleted == 1 ? (
            <span className="bg-danger p-2 rounded-1">Yes</span>
          ) : (
            <span span className="bg-success rounded-1 p-2 ">
              No
            </span>
          )}
        </p>
      ),
      sortable: false,
    },
    {
      name: "Permanent Delete",
      selector: (row) => (
        <button
          onClick={() => {
            setDeleteModal(true);
            setSelectedItem(row);
          }}
          className="btn btn-danger font-12"
        >
          Permanent Delete
        </button>
      ),
      sortable: false,
      width: "220px",
    },
    {
      name: "Detail",
      selector: (row) => (
        <>
          <button
            onClick={() => navigate(`${"/admin/user-detail/"}${row.id}`)}
            className="btn btn-primary"
          >
            Detail
          </button>
        </>
      ),
      sortable: false,
    },
  ];

  const UserActiveDeActiveHandle = (userId) => {
    setIsLoaderInfo(true);
    apiPost(
      ENDPOINTS.UserActiveDeActive,
      { user_id: userId },
      (res) => {
        setReloadData(!reloadData);
        setIsLoaderInfo(false);
      },
      (error) => {
        console.log(error, " error");
        setIsLoaderInfo(false);
      }
    );
  };

  return (
    <>
      <TableComponent
        title="Users"
        endPoint={ENDPOINTS.UserPaginated}
        headers={columns}
        reloadData={reloadData}
        // searchObject={ }
      />
      <UpdatePhoneNumber
        refreshDataPro={() => {
          setReloadData(!reloadData);
        }}
        showModalPro={updateModal}
        setModalHidePro={() => setUpdateModal(false)}
        item={selectedItem}
      />

      <DeleteItemModal
        url={ENDPOINTS.DeleteUser}
        body={{
          user_id: selectedItem?.id,
        }}
        reloadData={() => {
          setDeleteModal(false);
          setReloadData(!reloadData);
        }}
        show={deleteModal}
        hideModal={() => setDeleteModal(false)}
        text={`Do you want to user ${selectedItem?.first_name} ${selectedItem?.last_name}`}
        title="Delete User"
      />
    </>
  );
};
export default Users;
