import React from 'react';
import CatImg from "../../assets/images/user.jpg";
import { BASE_IMG_URL } from '../../util/EndPoint';


export const UserAvatar = ({ user }) => {
    return (
        <a className='a-none-tag' href={`/admin/user-detail/${user?.id}`}>

            <div className="d-flex align-items-center ">
                <div className="symbol symbol-45px me-2 border border-primary">
                    <img src={user?.profile_picture ? BASE_IMG_URL + user?.profile_picture : CatImg} alt="dsdasda" />
                </div>
                <div className="d-flex justify-content-start flex-column">
                    <p className="text-dark fw-bold text-hover-primary fs-6 mb-0">{user?.first_name} {user?.last_name}</p>
                    <span className="text-muted fw-semibold text-muted d-block fs-7">{user?.email}</span>
                </div>
            </div>
        </a>
    )
}
