import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { useRecoilState } from "recoil";
import "./App.css";
import Loader from "./components/global/Loader";
import SnackBar from "./components/global/SnackBar";
import AdminLayout from "./components/layout/AdminLayout";
import "./custom.scss";
import AboutUs from "./pages/AboutUs";
import Adds from "./pages/Adds";
import Badges from "./pages/Badges";
import ContactUs from "./pages/ContactUs";
import Dashboard from "./pages/Dashboard";
import Disorders from "./pages/Disorders";
import ErrorPage from "./pages/ErrorPage";
import Faqs from "./pages/Faqs";
import LinksInfo from "./pages/LinksInfo";
import Login from "./pages/Login";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Reporting from "./pages/Reporting";
import Symptoms from "./pages/Symptoms";
import TermsAndServices from "./pages/TermsAndServices";
import Users from "./pages/Users";
import UsersDetail from "./pages/UsersDetail";
import UserSubcription from "./pages/UserSubcription";
import { isLoaderState, snakeBarState } from "./recoil/RecoilStore";
function App() {
  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);
  return (
    <>
      {isLoaderInfo && <Loader />}

      <div className="App">
        <Router>
          <Routes>
            <Route exact path={``} element={<Login />} />
            <Route path={`/admin`} element={<AdminLayout />}>
              <Route exact path="dashboard" element={<Dashboard />} />
              <Route exact path="user-detail/:id" element={<UsersDetail />} />
              <Route exact path="users" element={<Users />} />
              <Route exact path="adds" element={<Adds />} />
              <Route exact path="badges" element={<Badges />} />
              <Route
                exact
                path="user-subcription"
                element={<UserSubcription />}
              />
              <Route exact path="symptoms" element={<Symptoms />} />
              <Route exact path="disorders" element={<Disorders />} />
              <Route exact path="links-info" element={<LinksInfo />} />
              <Route exact path="faqs" element={<Faqs />} />
              <Route exact path="reporting" element={<Reporting />} />
              <Route exact path="contact-us" element={<ContactUs />} />
              <Route exact path="privacy-policy" element={<PrivacyPolicy />} />
              <Route
                exact
                path="terms-and-services"
                element={<TermsAndServices />}
              />
              <Route exact path="about-us" element={<AboutUs />} />
            </Route>
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Router>
      </div>
      <SnackBar
        closeSnackPro={() =>
          setSnackBarInfo({ snackStatus: false, snackColor: "", snackMsg: "" })
        }
        snackInfoPro={snackBarInfo}
      />
    </>
  );
}

export default App;
