import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaBell } from "react-icons/fa6";
import { useRecoilState } from "recoil";
import { isLoaderState, snakeBarState } from "../../../recoil/RecoilStore";
import { apiPost } from "../../../util/ApiRequest";
import "./DeleteItemModal.scss";



const DeleteItemModal = ({ show, hideModal, text, title, reloadData, url, body, infoModal }) => {
    const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
    const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);

    const deleteItem = () => {
        setIsLoaderInfo(true)
        apiPost(
            url,
            body,
            (res) => {
                setSnackBarInfo({
                    snackStatus: true,
                    snackColor: "bg-primary",
                    snackMsg: "successfully",
                });
                setIsLoaderInfo(false)
                reloadData();

            },
            (error) => {
                setSnackBarInfo({
                    snackStatus: true,
                    snackColor: "bg-danger",
                    snackMsg: error?.data?.message[0] ? error?.data?.message[0] : "there is an error",
                });
                setIsLoaderInfo(false)
            }
        );
    };



    return (
        <>
            <Modal className={`bg-gradient-custom  ${infoModal ? "bg-gradient-danger-info" : "bg-gradient-danger"}`} show={show} onHide={() => hideModal()}>
                <Modal.Header>
                    <Modal.Title className="font-14 text-white">{"Your attention is required"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <h2 className="delete-model-icon text-white text-center"><FaBell /></h2>
                        <h3 className="mb-2 text-white text-center">{title} </h3>
                        <h6 className="text-white text-center">{text}</h6>
                    </div> </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">

                    <Button variant="secondary" className="font-14" onClick={() => deleteItem()}>
                        Yes, Do it
                    </Button>

                    <button className="btn text-white font-14" onClick={hideModal}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal >
        </>
    );
};

export default DeleteItemModal;
