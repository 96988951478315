import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ReportingYearBar from '../components/charts/ReportingYearBar';
import UsersYearBar from '../components/charts/UsersYearBar';
const Dashboard = () => {

  return (
    <>
      <Container fluid className="mt-4">


        <Row className="">
          <Col md={6} className="mb-2">
            <UsersYearBar />
          </Col>
          <Col md={6} className="mb-2">
            <ReportingYearBar />
          </Col>

        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
