import React from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import CatImg from "../../assets/images/user.jpg";
import { BASE_IMG_URL } from "../../util/EndPoint";

const SingleImage = ({ image, style }) => {
  const handle = useFullScreenHandle();

  return (
    <>
      <FullScreen handle={handle}>
        <div
          style={
            handle.active == false
              ? {}
              : { height: "100vh", justifyContent: "center" }
          }
          className={`d-flex align-items-center`}
        >
          <img
            onClick={handle.enter}
            className="single-img"
            style={
              handle.active == false
                ? style
                : {
                    width: "500px",
                    height: "500px",
                    objectFit: "fill",
                    cursor: "pointer",
                  }
            }
            src={image ? BASE_IMG_URL + image : CatImg}
            alt="image"
          />
        </div>
      </FullScreen>
    </>
  );
};

export default SingleImage;
