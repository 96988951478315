import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useRecoilState } from "recoil";
import { isLoaderState } from "../recoil/RecoilStore";
import { apiGetAuth, apiPost } from "../util/ApiRequest";
import { ENDPOINTS } from "../util/EndPoint";

const LinksInfo = () => {
  const formRef = React.createRef();

  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);

  useEffect(() => {
    getHeaderData();
  }, []);

  const [appContent, setAppContent] = useState({
    web_url: "",
    help_url: "",
    courses_url: "",
    therapy_url: "",
    strengths_url: "",
    editable: "",
  });

  const getHeaderData = () => {
    apiGetAuth(
      ENDPOINTS.DashboardData,
      (res) => {
        console.log(res, "res");
        setAppContent((prevState) => ({
          ...prevState,
          web_url: res?.app_content?.web_url,
          help_url: res?.app_content?.help_url,
          therapy_url: res?.app_content?.therapy_url,
          courses_url: res?.app_content?.courses_url,
          strengths_url: res?.app_content?.strengths_url,
          contact_us_url: res?.app_content?.contact_us_url,
        }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleSubmit = (event) => {
    setIsLoaderInfo(true);
    event.preventDefault();
    const body = new FormData(event.target);
    apiPost(
      ENDPOINTS.UpdateContent,
      body,
      (res) => {
        getHeaderData();
        setIsLoaderInfo(false);
        document.getElementById("formFile").value = "";
        setAppContent((prevState) => ({
          ...prevState,
          editable: "",
        }));
      },
      (error) => {
        setIsLoaderInfo(false);
        setAppContent((prevState) => ({
          ...prevState,
          editable: "",
        }));
      }
    );
  };

  return (
    <>
      <Container fluid className="mt-4">
        <Row className="mt-4">
          <Col md={6} className="mb-3">
            <Form onSubmit={handleSubmit} ref={formRef}>
              <input type="hidden" name="update_key" value={"web_url"} />
              <h3>Web Url</h3>
              <Form.Control
                name="update_value"
                disabled={appContent?.editable == "web_url" ? false : true}
                type="text"
                placeholder="web url"
                defaultValue={appContent?.web_url}
              />

              {appContent.editable == "web_url" && (
                <Button type="submit" className="mt-3">
                  Update
                </Button>
              )}
            </Form>
            {appContent.editable != "web_url" && (
              <Button
                onClick={() => {
                  if (appContent.editable != "web_url") {
                    setAppContent((prevState) => ({
                      ...prevState,
                      editable: "web_url",
                    }));
                  }
                }}
                type={"button"}
                className="mt-3"
              >
                {"Edit"}
              </Button>
            )}
          </Col>
          <Col md={6} className="mb-3">
            <Form onSubmit={handleSubmit} ref={formRef}>
              <input type="hidden" name="update_key" value={"help_url"} />
              <h3>Help Url</h3>
              <Form.Control
                name="update_value"
                disabled={appContent.editable == "help_url" ? false : true}
                type="text"
                placeholder="help url"
                defaultValue={appContent?.help_url}
              />

              {appContent.editable == "help_url" && (
                <Button type="submit" className="mt-3">
                  Update
                </Button>
              )}
            </Form>
            {appContent.editable != "help_url" && (
              <Button
                onClick={() => {
                  if (appContent.editable != "help_url") {
                    setAppContent((prevState) => ({
                      ...prevState,
                      editable: "help_url",
                    }));
                  }
                }}
                type={"button"}
                className="mt-3"
              >
                {"Edit"}
              </Button>
            )}
          </Col>

          <Col md={6} className="mb-3">
            <Form onSubmit={handleSubmit} ref={formRef}>
              <input type="hidden" name="update_key" value={"courses_url"} />
              <h3>Courses Url</h3>
              <Form.Control
                name="update_value"
                disabled={appContent.editable == "courses_url" ? false : true}
                type="text"
                placeholder="courses url"
                defaultValue={appContent?.courses_url}
              />

              {appContent.editable == "courses_url" && (
                <Button type="submit" className="mt-3">
                  Update
                </Button>
              )}
            </Form>
            {appContent.editable != "courses_url" && (
              <Button
                onClick={() => {
                  if (appContent.editable != "courses_url") {
                    setAppContent((prevState) => ({
                      ...prevState,
                      editable: "courses_url",
                    }));
                  }
                }}
                type={"button"}
                className="mt-3"
              >
                {"Edit"}
              </Button>
            )}
          </Col>

          <Col md={6} className="mb-3">
            <Form onSubmit={handleSubmit} ref={formRef}>
              <input type="hidden" name="update_key" value={"therapy_url"} />
              <h3>Therapy Url</h3>
              <Form.Control
                name="update_value"
                disabled={appContent.editable == "therapy_url" ? false : true}
                type="text"
                placeholder="therapy url"
                defaultValue={appContent?.therapy_url}
              />

              {appContent.editable == "therapy_url" && (
                <Button type="submit" className="mt-3">
                  Update
                </Button>
              )}
            </Form>
            {appContent.editable != "therapy_url" && (
              <Button
                onClick={() => {
                  if (appContent.editable != "therapy_url") {
                    setAppContent((prevState) => ({
                      ...prevState,
                      editable: "therapy_url",
                    }));
                  }
                }}
                type={"button"}
                className="mt-3"
              >
                {"Edit"}
              </Button>
            )}
          </Col>

          <Col md={6} className="mb-3">
            <Form onSubmit={handleSubmit} ref={formRef}>
              <input type="hidden" name="update_key" value={"strengths_url"} />
              <h3>Strengths Url</h3>
              <Form.Control
                name="update_value"
                disabled={
                  appContent?.editable == "strengths_url" ? false : true
                }
                type="text"
                placeholder="Strengths url"
                defaultValue={appContent?.strengths_url}
              />

              {appContent?.editable == "strengths_url" && (
                <Button type="submit" className="mt-3">
                  Update
                </Button>
              )}
            </Form>
            {appContent?.editable != "strengths_url" && (
              <Button
                onClick={() => {
                  if (appContent?.editable != "strengths_url") {
                    setAppContent((prevState) => ({
                      ...prevState,
                      editable: "strengths_url",
                    }));
                  }
                }}
                type={"button"}
                className="mt-3"
              >
                {"Edit"}
              </Button>
            )}
          </Col>

          <Col md={6} className="mb-3">
            <Form onSubmit={handleSubmit} ref={formRef}>
              <input type="hidden" name="update_key" value={"contact_us_url"} />
              <h3>Contact Us Url</h3>
              <Form.Control
                name="update_value"
                disabled={
                  appContent?.editable == "contact_us_url" ? false : true
                }
                type="text"
                placeholder="Contact us url"
                defaultValue={appContent?.contact_us_url}
              />

              {appContent?.editable == "contact_us_url" && (
                <Button type="submit" className="mt-3">
                  Update
                </Button>
              )}
            </Form>
            {appContent?.editable != "contact_us_url" && (
              <Button
                onClick={() => {
                  if (appContent?.editable != "contact_us_url") {
                    setAppContent((prevState) => ({
                      ...prevState,
                      editable: "contact_us_url",
                    }));
                  }
                }}
                type={"button"}
                className="mt-3"
              >
                {"Edit"}
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LinksInfo;
