import React, { useState } from "react";
import { CiEdit, CiTrash } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import CreateSymptoms from "../components/forms/CreateSymptoms";
import DeleteItemModal from "../components/forms/DeleteItemModal";
import UpdateSymptoms from "../components/forms/UpdateSymptoms";
import TableComponent from "../components/global/TableComponent";
import { ENDPOINTS } from "../util/EndPoint";

const Symptoms = () => {
  const navigate = useNavigate();

  const [reloadData, setReloadData] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectItem, setSelectItem] = useState({});

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: false,
      maxWidth: "250px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: false,
    },
    {
      name: "Update",
      selector: (row) => (
        <>
          <button
            onClick={() => {
              setSelectItem(row);
              setUpdateModal(true);
            }}
            className="btn btn-primary"
          >
            <CiEdit />
          </button>
        </>
      ),
      width: "100px",
    },

    {
      name: "Delete",
      selector: (row) => (
        <>
          <button
            onClick={() => {
              setSelectItem(row);
              setDeleteModal(true);
            }}
            className="btn btn-danger"
          >
            <CiTrash />
          </button>
        </>
      ),
      width: "100px",
    },
  ];

  return (
    <>
      <div className=" mb-2 text-end">
        <button
          className="btn btn-primary"
          onClick={() => setCreateModal(true)}
        >
          Add symptoms
        </button>
      </div>

      <TableComponent
        title="Symptoms"
        endPoint={ENDPOINTS.SymptomsPaginated}
        headers={columns}
        // searchObject={ }
        reloadData={reloadData}
      />

      <UpdateSymptoms
        setModalHidePro={() => setUpdateModal(false)}
        showModalPro={updateModal}
        selectItem={selectItem}
        refreshDataPro={() => setReloadData(!reloadData)}
      />
      <CreateSymptoms
        setModalHidePro={() => setCreateModal(false)}
        showModalPro={createModal}
        refreshDataPro={() => setReloadData(!reloadData)}
      />

      <DeleteItemModal
        url={ENDPOINTS.SymptomsDisordersDelete}
        body={{
          symptoms_disorder_id: selectItem?.id,
          type: "symptoms",
        }}
        reloadData={() => {
          setDeleteModal(false);
          setReloadData(!reloadData);
        }}
        show={deleteModal}
        hideModal={() => setDeleteModal(false)}
        text={`Do you want to delete ${selectItem?.name} Symptoms?`}
        title="Delete Symptoms"
      />
    </>
  );
};
export default Symptoms;
