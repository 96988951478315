import Form from 'react-bootstrap/Form';

export const CustomTextArea = ({ label, name, required, defaultValue }) => {
    return (
        <div>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                placeholder={`Enter ${label}`}
                name={name}
                defaultValue={defaultValue}
                required={required}
                as="textarea" />
        </div>
    )

}

CustomTextArea.defaultProps = {
    label: "",
    name: "",
    required: true,
    defaultValue: "",
};

export default CustomTextArea;

