import React from 'react';

import Crisis from '../../assets/images/gifs/crisis.gif';
import Good from '../../assets/images/gifs/good.gif';
import Happy from '../../assets/images/gifs/happy.gif';
import Ok from '../../assets/images/gifs/ok.gif';
import Struggling from '../../assets/images/gifs/struggling.gif';


const CurrentUserMood = ({ currentMood }) => {
    let moodImage;

    switch (currentMood?.mood) {
        case 'crisis':
            moodImage = Crisis;
            break;
        case 'good':
            moodImage = Good;
            break;
        case 'happy':
            moodImage = Happy;
            break;
        case 'ok':
            moodImage = Ok;
            break;
        case 'struggling':
            moodImage = Struggling;
            break;
        default:
            moodImage = null;
    }



    return (
        <div className='current-mood'>
            {moodImage == null && (<h6>no mode updated</h6>)}
            {moodImage && <img src={moodImage} alt={moodImage} />}
        </div>
    )
}

export default CurrentUserMood