import React from "react";
import Modal from "react-bootstrap/Modal";


const DetailFaqs = ({ selectedItem, showModalPro, setModalHidePro }) => {


    return (
        <>

            <Modal show={showModalPro} onHide={() => setModalHidePro()}>
                <Modal.Header
                    closeButton
                    className="bg-primary text-white custom-modal-header"
                >
                    <Modal.Title className="font-18">Question: {selectedItem?.question}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedItem?.answer}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DetailFaqs;
