import React, { useState } from "react";
import { CiEdit, CiTrash } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import CreateDisorders from "../components/forms/CreateDisorders";
import DeleteItemModal from "../components/forms/DeleteItemModal";
import UpdateDisorders from "../components/forms/UpdateDisorders";
import TableComponent from "../components/global/TableComponent";
import { ENDPOINTS } from "../util/EndPoint";
const Disorders = () => {
  const navigate = useNavigate();
  const [reloadData, setReloadData] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [selectItem, setSelectItem] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: false,
      maxWidth: "250px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: false,
    },
    {
      name: "Update",
      selector: (row) => (
        <>
          <button
            onClick={() => {
              setSelectItem(row);
              setUpdateModal(true);
            }}
            className="btn btn-primary"
          >
            <CiEdit />
          </button>
        </>
      ),
      width: "100px",
    },
    {
      name: "Delete",
      selector: (row) => (
        <>
          <button
            onClick={() => {
              setSelectItem(row);
              setDeleteModal(true);
            }}
            className="btn btn-danger"
          >
            <CiTrash />
          </button>
        </>
      ),
      width: "100px",
    },
  ];

  return (
    <>
      <div className=" mb-2 text-end">
        <button
          className="btn btn-primary"
          onClick={() => setCreateModal(true)}
        >
          Add disorders
        </button>
      </div>
      <TableComponent
        title="Disorders"
        endPoint={ENDPOINTS.DisordersPaginated}
        headers={columns}
        // searchObject={ }
        reloadData={reloadData}
      />

      <DeleteItemModal
        url={ENDPOINTS.SymptomsDisordersDelete}
        body={{
          symptoms_disorder_id: selectItem?.id,
          type: "disorders",
        }}
        reloadData={() => {
          setDeleteModal(false);
          setReloadData(!reloadData);
        }}
        show={deleteModal}
        hideModal={() => setDeleteModal(false)}
        text={`Do you want to delete ${selectItem?.name} disorders?`}
        title="Delete Disorders"
      />

      <CreateDisorders
        setModalHidePro={() => setCreateModal(false)}
        showModalPro={createModal}
        refreshDataPro={() => setReloadData(!reloadData)}
      />
      <UpdateDisorders
        setModalHidePro={() => setUpdateModal(false)}
        showModalPro={updateModal}
        refreshDataPro={() => setReloadData(!reloadData)}
        selectItem={selectItem}
      />
    </>
  );
};
export default Disorders;
