import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { useEffect, useState } from "react";
import { Button, Form, Image } from 'react-bootstrap';
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useRecoilState } from 'recoil';
import { isLoaderState } from '../recoil/RecoilStore';
import { apiGetAuth, apiPost } from '../util/ApiRequest';
import { BASE_IMG_URL, ENDPOINTS } from '../util/EndPoint';
const AboutUs = () => {
  const formRef = React.createRef();

  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);


  useEffect(() => {
    getHeaderData();
  }, []);



  const [appContent, setAppContent] = useState({
    about_1: '',
    about_2: '',
    about_image: '',
    web_url: '',
    editable: ''
  });

  const getHeaderData = () => {
    apiGetAuth(
      ENDPOINTS.DashboardData,
      (res) => {
        console.log(res, 'res')
        setAppContent(prevState => ({
          ...prevState,
          about_1: res?.app_content?.about_1,

        }));

        setAppContent(prevState => ({
          ...prevState,
          about_2: res?.app_content?.about_2,
        }));

        setAppContent(prevState => ({
          ...prevState,
          about_image: res?.app_content?.about_image,
        }));
        setAppContent(prevState => ({
          ...prevState,
          web_url: res?.app_content?.web_url,
        }));
      },
      (error) => {
        console.log(error)
      }
    );
  };



  const handleUpdateContent = (updateKey) => {
    setIsLoaderInfo(true)
    let updateValue = '';
    if (updateKey == 'about_1') { updateValue = appContent.about_1 }
    if (updateKey == 'about_2') { updateValue = appContent.about_2 }
    if (updateKey == 'about_image') { updateValue = appContent.about_image }
    apiPost(
      ENDPOINTS.UpdateContent,
      {
        update_key: updateKey,
        update_value: updateValue
      },
      (res) => {
        setIsLoaderInfo(false)
        getHeaderData()
        setAppContent(prevState => ({
          ...prevState,
          editable: '',
        }));
      },
      (error) => {
        setIsLoaderInfo(false)
        console.log(error)
      }
    );
  }


  const handleSubmit = (event) => {
    setIsLoaderInfo(true)

    event.preventDefault();
    const body = new FormData(event.target);
    apiPost(
      ENDPOINTS.UpdateContent,
      body,
      (res) => {
        getHeaderData()
        setIsLoaderInfo(false)
        document.getElementById('formFile').value = '';
        setAppContent(prevState => ({
          ...prevState,
          editable: '',
        }));
      },
      (error) => {
        setIsLoaderInfo(false)

      }
    );
  };




  return (
    <>
      <Container fluid className="mt-4">
        <Row className='mt-4'>

          <Col md={12} className='mb-3'>
            <div className="mt-2 card-header-customs ">

              <Card >
                <Card.Body>
                  <Card.Title>About Section 1</Card.Title>
                  <CKEditor
                    disabled={appContent.editable == 'about_1' ? false : true}
                    editor={ClassicEditor}
                    data={appContent?.about_1}
                    onReady={editor => {
                      console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();

                      setAppContent(prevState => ({
                        ...prevState,
                        about_1: data,

                      }));
                      console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log('Focus.', editor);
                    }}
                  />
                  <Button variant="primary" className='mt-2' onClick={() => {
                    if (appContent.editable != 'about_1') {
                      setAppContent(prevState => ({
                        ...prevState,
                        editable: 'about_1',
                      }));
                    }
                    if (appContent.editable == 'about_1') {
                      handleUpdateContent('about_1')
                    }


                  }} >{appContent.editable == 'about_1' ? 'Update' : 'Edit'}</Button>
                </Card.Body>
              </Card>
            </div >
          </Col>



          <Col md={12} className='mb-3'>
            <div className="mt-2 card-header-customs ">

              <Card >
                <Card.Body>
                  <Card.Title>About Section 2</Card.Title>
                  <CKEditor
                    disabled={appContent.editable == 'about_2' ? false : true}
                    editor={ClassicEditor}
                    data={appContent?.about_2}
                    onReady={editor => {
                      console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setAppContent(prevState => ({
                        ...prevState,
                        about_2: data,

                      }));
                      console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log('Focus.', editor);
                    }}
                  />
                  <Button variant="primary" className='mt-2' onClick={() => {
                    if (appContent.editable != 'about_2') {
                      setAppContent(prevState => ({
                        ...prevState,
                        editable: 'about_2',
                      }));
                    }
                    if (appContent.editable == 'about_2') {
                      handleUpdateContent('about_2')
                    }

                  }} >{appContent.editable == 'about_2' ? 'Update' : 'Edit'}</Button>
                </Card.Body>
              </Card>
            </div >
          </Col>

          <Col md={6} className='mb-3'>
            <Form onSubmit={handleSubmit} >
              <input type="hidden" name="update_key" value={'about_image'} />
              <Image className='about-us-image' src={BASE_IMG_URL + appContent?.about_image} thumbnail />
              <Form.Group className="mb-3">
                <Form.Label className='mt-3'>Image</Form.Label>
                <Form.Control name='media' id="formFile" type="file" />

              </Form.Group>
              <Button

                type={'submit'}
                className='mt-3'
              >Update</Button>

            </Form>
          </Col>


      
        </Row>
      </Container>
    </>
  );
};

export default AboutUs;
