import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
// import { animated } from 'react-spring';
import CompanyImg from "../../assets/images/companies.png";
import MarketPlaceImg from "../../assets/images/marketplace.png";
import IssuesImg from "../../assets/images/report.png";
import UserImg from "../../assets/images/users.png";
import { useCountAnimation } from "../../util/Helper";
import { apiGetAuth } from "./../../util/ApiRequest";
import { ENDPOINTS } from "./../../util/EndPoint";
import Loader from "./Loader";


const HeaderCard = () => {

  useEffect(() => {
    // getHeaderData();
  }, []);

  const [loader, setLoader] = useState(false);
  const [headerData, setHeaderData] = useState({
    user_count: 0,
    order_count: 0,
    cancel_order_count: 0,
    completed_order_count: 0,
  });

  const getHeaderData = () => {
    setLoader(true);
    apiGetAuth(
      ENDPOINTS.AppHeader,
      (res) => {
        setHeaderData(res)
        setLoader(false);
      },
      (error) => {
        console.log(error)
        setLoader(false);

      }
    );
  };

  const userCount = useCountAnimation(0, headerData.user_count);
  const orderCount = useCountAnimation(0, headerData.order_count);
  const cancelOrderCount = useCountAnimation(0, headerData.cancel_order_count);
  const completedOrderCount = useCountAnimation(0, headerData.completed_order_count);

  return (
    <>
      <Container fluid className="mt-2 card-header-customs">
        <Row>
          <Col xl="3" lg="6" md="12">
            <div className="header-card">
              <div className="card-inner ">
                <div>

                  <img className="user-img" src={UserImg} alt="Users Img" />
                </div>
                <div className="ms-4">

                  <h5 className="mb-0 text-white">
                    {/* <animated.span className="number">
                      {userCount.interpolate((val) => Math.floor(val))}
                    </animated.span> */}
                    0

                  </h5>
                  <p className="mb-0 font-12 text-white">Total Users</p>
                </div>
              </div>

            </div>
          </Col>
          <Col xl="3" lg="6" md="12">
            <div className="header-card">
              <div className="card-inner ">
                <div>
                  <img className="user-img " src={CompanyImg} alt="Users Img" />
                </div>
                <div className="ms-4">
                  <h5 className="mb-0 text-white">
                    {/* <animated.span className="number">
                      {orderCount.interpolate((val) => Math.floor(val))}
                    </animated.span> */}
                    0
                  </h5>
                  <p className="mb-0 font-12 text-white">Total Orders</p>
                </div>
              </div>
            </div>
          </Col>
          <Col xl="3" lg="6" md="12">
            <div className="header-card">
              <div className="card-inner ">
                <div>
                  <img
                    className="user-img"
                    src={MarketPlaceImg}
                    alt="Users Img"
                  />
                </div>
                <div className="ms-4">
                  <h5 className="mb-0 text-white">
                    {/* <animated.span className="number">
                      {cancelOrderCount.interpolate((val) => Math.floor(val))}
                    </animated.span> */}
                    0
                  </h5>
                  <p className="mb-0 font-12 text-white">
                    Total Cancel order count
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xl="3" lg="6" md="12">
            <div className="header-card">

              <div className="card-inner ">
                <div>
                  <img className="user-img " src={IssuesImg} alt="Users Img" />
                </div>
                <div className="ms-4">
                  <h5 className="mb-0 text-white">
                    {/* <animated.span className="number">
                      {completedOrderCount.interpolate((val) => Math.floor(val))}
                    </animated.span> */}
                    0
                  </h5>
                  <p className="mb-0 font-12 text-white">
                    Total Complete orders
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="">{loader && <Loader />}</div>
    </>
  );
};

export default HeaderCard;
