import React, { useState } from "react";
import { CiEdit, CiTrash } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import CreateBadge from "../components/forms/CreateBadge";
import DeleteItemModal from "../components/forms/DeleteItemModal";
import UpdateBadge from "../components/forms/UpdateBadge";
import CustomTooltip from "../components/global/CustomTooltip";
import SingleImage from "../components/global/SingleImage";
import TableComponent from "../components/global/TableComponent";
import { ENDPOINTS } from "../util/EndPoint";

const Badges = () => {
  const navigate = useNavigate();

  const [reloadData, setReloadData] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectItem, setSelectItem] = useState({});

  const columns = [
    {
      name: "Id",
      selector: (row) => row?.id,
      sortable: false,
      maxWidth: "250px",
    },
    {
      name: "Name",
      selector: (row) => row?.name,
      sortable: false,
    },
    {
      name: "Price",
      selector: (row) => row?.price,
      sortable: false,
    },
    {
      name: "Image",
      selector: (row) => (
        <SingleImage
          style={{ width: 40, height: 40, margin: 6, border: "1px solid #000" }}
          image={row?.media}
        />
      ),
      sortable: false,
    },
    {
      name: "Description",
      selector: (row) => <CustomTooltip descriptionPro={row?.description} />,
      sortable: false,
      wrap: true,
      maxWidth: "400px",
      minWidth: "250px",
    },
    {
      name: "Update",
      selector: (row) => (
        <>
          <button
            onClick={() => {
              setSelectItem(row);
              setUpdateModal(true);
            }}
            className="btn btn-primary"
          >
            <CiEdit />
          </button>
        </>
      ),
      width: "100px",
    },

    {
      name: "Delete",
      selector: (row) => (
        <>
          <button
            onClick={() => {
              setSelectItem(row);
              setDeleteModal(true);
            }}
            className="btn btn-danger"
          >
            <CiTrash />
          </button>
        </>
      ),
      width: "100px",
    },
  ];

  return (
    <>
      <div className=" mb-2 text-end">
        <button
          className="btn btn-primary"
          onClick={() => setCreateModal(true)}
        >
          Add Badge
        </button>
      </div>

      <CreateBadge
        setModalHidePro={() => setCreateModal(false)}
        showModalPro={createModal}
        refreshDataPro={() => setReloadData(!reloadData)}
      />

      <DeleteItemModal
        url={ENDPOINTS.BadgesDelete}
        body={{
          badge_id: selectItem?.id,
        }}
        reloadData={() => {
          setDeleteModal(false);
          setReloadData(!reloadData);
        }}
        show={deleteModal}
        hideModal={() => setDeleteModal(false)}
        text={`Do you want to delete ${selectItem?.name} badge?`}
        title="Delete Badge"
      />

      <UpdateBadge
        selectedItem={selectItem}
        setModalHidePro={() => setUpdateModal(false)}
        showModalPro={updateModal}
        refreshDataPro={() => setReloadData(!reloadData)}
      />
      <TableComponent
        title="Badges"
        endPoint={ENDPOINTS.BadgesPaginated}
        headers={columns}
        reloadData={reloadData}

        // searchObject={ }
      />
    </>
  );
};
export default Badges;
