import React from 'react';

export const CustomNumberInput = ({ label, name, required, defaultValue }) => {

    const handleInput = (event) => {
        const value = event.target.value;
        const regex = /^[0-9]*\.?[0-9]*$/; // Regex pattern to allow numbers and a single decimal point

        if (!regex.test(value)) {
            event.target.value = value.slice(0, -1); // Remove the last character if it doesn't match the pattern
        }
    };



    return (
        <div>
            <label className="mb-2">{label}:</label>
            <input
                placeholder={`Enter ${label}`}
                className="form-control mb-2"
                name={name}
                type={'text'}
                required={required}
                defaultValue={defaultValue}
                onInput={handleInput}

            />
        </div>
    )

}

CustomNumberInput.defaultProps = {
    label: "",
    name: "",
    required: true,
    defaultValue: "",
};

export default CustomNumberInput;

