import moment from 'moment';
import React from "react";
import { useNavigate } from 'react-router-dom';
import TableComponent from "../components/global/TableComponent";
import { UserAvatar } from "../components/global/UserAvatar";
import { ENDPOINTS } from "../util/EndPoint";


const UserSubcription = () => {
  const navigate = useNavigate();

  const columns = [
    {
      name: 'Price',
      selector: (row) => row?.price,
      sortable: false,
    },
   
    {
      name: 'Start Date',
      selector: (row) => moment.utc(row?.start_date_time).format('DD-MMM-YYYY hh:mm a'),
      sortable: false,
      maxWidth: '400px',
      minWidth: '250px'
    },
    
    {
      name: 'End Date',
      selector: (row) => moment.utc(row?.end_date_time).format('DD-MMM-YYYY hh:mm a'),
      sortable: false,
      maxWidth: '400px',
      minWidth: '250px'
    },
  
    {
      name: 'Reported to',
      selector: (row) => <div className="m-2">  <UserAvatar user={row.user} /> </div>,
      sortable: false,
    },
  ];


  return (
    <>

      <TableComponent
        title="User Subcription"
        endPoint={ENDPOINTS.SubcriptionPaginated}
        headers={columns}
      // searchObject={ }

      />
    </>
  )
}
export default UserSubcription;
