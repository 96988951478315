import React from 'react';

export const CustomInputFile = ({ label, name,  required }) => {
    return (
        <div>
          <label className="form-label">{label}</label>
          <input className="form-control" required={required} name={name} type="file" />
        </div>
    )

}

CustomInputFile.defaultProps = {
    required: true,
};

export default CustomInputFile;

