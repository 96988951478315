import moment from 'moment';
import React, { useEffect, useRef, useState } from "react";
import { Form, Image } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';
import Modal from "react-bootstrap/Modal";
import ReactPlayer from 'react-player';
import { useRecoilState } from "recoil";
import { isLoaderState, snakeBarState } from "../../recoil/RecoilStore";
import { apiPost } from "../../util/ApiRequest";
import { BASE_IMG_URL, ENDPOINTS } from "../../util/EndPoint";
import CustomDateTimeInput from "../global/CustomDateTimeInput";
import CustomInput from "../global/CustomInput";
import CustomInputFile from "../global/CustomInputFile";
import PlacementSelect from "../global/PlacementSelect";


const UpdateAdds = ({ showModalPro, setModalHidePro, refreshDataPro, selectedItem }) => {
    const [existsItem, setExistsItem] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
    const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);

    const playerRef = useRef(null);

    useEffect(() => {
        return () => {
            if (playerRef.current) {
                playerRef.current.seekTo(0);
                playerRef.current.getInternalPlayer().pause();
            }
        };
    }, []);


    const handleSubmit = (event) => {
        setIsLoaderInfo(true);
        event.preventDefault();
        const stopTime = document.getElementsByName('end_date_time')[0].value;
        const startTime = document.getElementsByName('start_date_time')[0].value;

        if (stopTime < startTime) {
            setSnackBarInfo({
                snackStatus: true,
                snackColor: "bg-danger",
                snackMsg: "Stop time must be greater than start time",
            });
        } else {

            const body = new FormData(event.target);
            apiPost(
                ENDPOINTS.AddsUpdate,
                body,
                (res) => {

                    if (res?.data.is_exists == false) {
                        setModalHidePro();
                        refreshDataPro();
                        setSnackBarInfo({
                            snackStatus: true,
                            snackColor: "bg-success",
                            snackMsg: "Successful",
                        });
                    }
                    if (res?.data.is_exists == true) {
                        setShowAlert(true)
                        setExistsItem(res?.data)
                    }
                    setIsLoaderInfo(false);
                },
                (error) => {
                    console.log(error);
                    setIsLoaderInfo(false);
                    setModalHidePro();
                    refreshDataPro();
                    setSnackBarInfo({
                        snackStatus: true,
                        snackColor: "bg-danger",
                        snackMsg: "There is an Error Plz Try Again ",
                    });
                }
            );
        }
    };

    return (
        <>

            <Modal show={showModalPro} onHide={() => setModalHidePro()}>
                <Modal.Header
                    closeButton
                    className="bg-primary text-white custom-modal-header"
                >
                    <Modal.Title className="font-18">Update Ad's</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <input  value={selectedItem?.id} name='add_id' type='hidden'/>
                        <CustomInput label={"Name"} defaultValue={selectedItem?.name} name={"name"} type={"text"} required={true} />
                        <CustomInput label={"Company"} name={"company"} defaultValue={selectedItem?.company} type={"text"} required={true} />
                        <CustomInput label={"Price"} name={"price"} defaultValue={selectedItem?.price} type={"number"} required={true} />
                        <CustomInput label={"Url"} name={"url"} defaultValue={selectedItem?.url} type={"text"} required={true} />

                        <CustomDateTimeInput
                            placeholder={'select start date'}
                            label={"Start date"}
                            name={"start_date_time"}
                            required={true}
                            defaultValue={moment.utc(selectedItem?.start_date_time).format('YYYY-MM-DDThh:mm')}
                        />
                        <div className="mt-2">
                            <CustomDateTimeInput
                                placeholder={'select emd date'}
                                label={"End date"}
                                name={"end_date_time"}
                                required={true}
                                defaultValue={moment.utc(selectedItem?.end_date_time).format('YYYY-MM-DDThh:mm')}

                            />
                        </div>
                        <div className="mt-2">
                            <PlacementSelect defaultValue={selectedItem?.location} name={'location'} label={"Placement"} />
                        </div>


                        <div className="mb-3 mt-3">
                            <label className="mb-2">{'Description'}</label>

                            <Form.Control
                                as="textarea"
                                placeholder="Description"
                                name={'description'}
                                defaultValue={selectedItem?.description}
                            />


                            <div className=" text-center mt-3">
                                {selectedItem?.file_type == 'image' && (
                                    <>
                                        {selectedItem?.media != null && (
                                            <Image className="image-detail-add" src={BASE_IMG_URL + selectedItem?.media} thumbnail />
                                        )}

                                    </>
                                )}
                                {selectedItem?.file_type == 'video' && (
                                    <>
                                        {selectedItem?.media != null && (
                                            <ReactPlayer

                                                ref={playerRef}

                                                className="video-player-custom" controls={true} playing={true} url={BASE_IMG_URL + selectedItem?.media} />
                                        )}

                                    </>
                                )}
                            </div>

                            <div className="mb-3 mt-3">
                                <CustomInputFile required={false} name={'media'} label={"File"} />
                            </div>


                        </div>


                        <input className="btn btn-secondary mt-3" type="submit" />
                    </form>

                    <Alert variant="danger" show={showAlert} className="mt-2" onClose={() => setShowAlert(false)} dismissible>
                        <Alert.Heading>Date Conflict</Alert.Heading>
                        <p>
                            The date is conflicting with ID # {existsItem?.id}, so you need to change the date to update the record associated with the selected date                        </p>
                    </Alert>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default UpdateAdds;
