import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import HeaderCard from "../global/HeaderCard";
import SidebarComponent from "../sidebar/SidebarComponent";

const AdminLayout = (props) => {
  const [toggled, setToggled] = useState(false);

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <>
      <div className="main-layout">
        <SidebarComponent toggled={toggled} handleToggleSidebar={handleToggleSidebar} />
        <main className="main-content table-responsive container-fluid p-4">
          <HeaderCard />
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default AdminLayout;
