import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { CgDanger } from "react-icons/cg";
import { useRecoilState } from "recoil";
import { isLoaderState, snakeBarState } from "../../recoil/RecoilStore";
import { apiPost } from "../../util/ApiRequest";


const DeleteItemModal = ({ show, hideModal, text, title, reloadData, url, body }) => {
    const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
    const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);

    const deleteItem = () => {
        setIsLoaderInfo(true)

        apiPost(
            url,
            body,
            (res) => {
                setSnackBarInfo({
                    snackStatus: true,
                    snackColor: "bg-success",
                    snackMsg: "successfully",
                });
                setIsLoaderInfo(false)
                reloadData();

            },
            (error) => {
                setSnackBarInfo({
                    snackStatus: true,
                    snackColor: "bg-danger",
                    snackMsg: error?.data?.message[0] ? error?.data?.message[0] : "there is an error",
                });
                setIsLoaderInfo(false)
            }
        );
    };



    return (
        <>
            <Modal show={show} onHide={() => hideModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body> <div className="d-flex gap-2"><h4 className="mb-0 text-danger"><CgDanger /> </h4> <h6 className="my-auto">{text}</h6></div> </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={hideModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => deleteItem()}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal >
        </>
    );
};

export default DeleteItemModal;
