import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BiSolidDashboard, BiSolidReport } from "react-icons/bi";
import { CgDanger } from "react-icons/cg";
import { CiLink } from "react-icons/ci";
import {
  FaBars,
  FaNotesMedical,
  FaQuestion,
  FaUserAltSlash,
  FaUsersCog,
  FaWindowClose,
} from "react-icons/fa";
import { ImProfile } from "react-icons/im";
import { MdPrivacyTip, MdStarBorderPurple500 } from "react-icons/md";
import { PiUsersThreeFill } from "react-icons/pi";
import { RiContactsBook2Fill, RiPagesFill } from "react-icons/ri";
import { SlBadge } from "react-icons/sl";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";

const SidebarComponent = () => {
  const [modalShow, setModalShow] = useState(false);

  const navigate = useNavigate();

  const [urlParam, setUrlParam] = useState(
    window.location.pathname.split("/").pop()
  );

  const [burgerShow, setBurgerShow] = useState(false);

  const logOut = () => {
    localStorage.clear();
    window.location.href = `/`;
  };

  return (
    <>
      <Sidebar
        className="sidebar-dashboard"
        backgroundColor="#6de8dc"
        collapsed={burgerShow}
      >
        <div className="sidebar-dashboard-logo">
          <button onClick={() => setBurgerShow(!burgerShow)}>
            {burgerShow ? <FaWindowClose /> : <FaBars />}
          </button>
        </div>

        <Menu iconShape="square">
          <MenuItem
            active={urlParam === "dashboard" ? true : false}
            onClick={() => {
              setUrlParam("dashboard");
              navigate(`${"/admin/dashboard"}`);
            }}
            icon={<BiSolidDashboard />}
            className="sidebar-menu-design  sidebar-menu-design-custom"
          >
            Dashboard
          </MenuItem>

          <MenuItem
            active={urlParam === "users" ? true : false}
            onClick={() => {
              setUrlParam("users");
              navigate(`${"/admin/users"}`);
            }}
            icon={<PiUsersThreeFill />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            Users
          </MenuItem>
          <MenuItem
            active={urlParam === "faqs" ? true : false}
            onClick={() => {
              setUrlParam("faqs");
              navigate(`${"/admin/faqs"}`);
            }}
            icon={<FaQuestion />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            Faqs
          </MenuItem>
          <MenuItem
            active={urlParam === "reporting" ? true : false}
            onClick={() => {
              setUrlParam("reporting");
              navigate(`${"/admin/reporting"}`);
            }}
            icon={<BiSolidReport />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            Reporting
          </MenuItem>

          <MenuItem
            active={urlParam === "symptoms" ? true : false}
            onClick={() => {
              setUrlParam("symptoms");
              navigate(`${"/admin/symptoms"}`);
            }}
            icon={<FaNotesMedical />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            Symptoms
          </MenuItem>

          <MenuItem
            active={urlParam === "adds" ? true : false}
            onClick={() => {
              setUrlParam("adds");
              navigate(`${"/admin/adds"}`);
            }}
            icon={<FaNotesMedical />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            Adds
          </MenuItem>
          <MenuItem
            active={urlParam === "disorders" ? true : false}
            onClick={() => {
              setUrlParam("disorders");
              navigate(`${"/admin/disorders"}`);
            }}
            icon={<RiContactsBook2Fill />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            Disorders
          </MenuItem>
          <MenuItem
            active={urlParam === "contact-us" ? true : false}
            onClick={() => {
              setUrlParam("contact-us");
              navigate(`${"/admin/contact-us"}`);
            }}
            icon={<FaUsersCog />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            Contact us
          </MenuItem>

          <MenuItem
            active={urlParam === "badges" ? true : false}
            onClick={() => {
              setUrlParam("badges");
              navigate(`${"/admin/badges"}`);
            }}
            icon={<SlBadge />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            Badges
          </MenuItem>
          <MenuItem
            active={urlParam === "links-info" ? true : false}
            onClick={() => {
              setUrlParam("links-info");
              navigate(`${"/admin/links-info"}`);
            }}
            icon={<CiLink />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            Links
          </MenuItem>

          <MenuItem
            active={urlParam === "user-subcription" ? true : false}
            onClick={() => {
              setUrlParam("user-subcription");
              navigate(`${"/admin/user-subcription"}`);
            }}
            icon={<MdStarBorderPurple500 />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            Subcription
          </MenuItem>
          <MenuItem
            active={urlParam === "about-us" ? true : false}
            onClick={() => {
              setUrlParam("about-us");
              navigate(`${"/admin/about-us"}`);
            }}
            icon={<RiPagesFill />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            About Us
          </MenuItem>

          <MenuItem
            active={urlParam === "terms-and-services" ? true : false}
            onClick={() => {
              setUrlParam("terms-and-services");
              navigate(`${"/admin/terms-and-services"}`);
            }}
            icon={<ImProfile />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            Terms And Services
          </MenuItem>

          <MenuItem
            active={urlParam === "privacy-policy" ? true : false}
            onClick={() => {
              setUrlParam("privacy-policy");
              navigate(`${"/admin/privacy-policy"}`);
            }}
            icon={<MdPrivacyTip />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            Privacy Policy
          </MenuItem>

          <MenuItem
            onClick={() => setModalShow(true)}
            icon={<FaUserAltSlash />}
            className="sidebar-menu-design"
          >
            Logout
          </MenuItem>
        </Menu>
      </Sidebar>

      <Modal show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="d-flex gap-2">
            <h4 className="mb-0 text-danger">
              <CgDanger />{" "}
            </h4>{" "}
            <h6 className="my-auto">Are you sure you want to logout ?</h6>
          </div>{" "}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            No
          </Button>
          <Button variant="primary" onClick={() => logOut()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SidebarComponent;
