import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import { isLoaderState } from '../../recoil/RecoilStore';
import { apiPost } from '../../util/ApiRequest';
import { ENDPOINTS } from '../../util/EndPoint';
import MoodItem from './MoodItem';

const MoodHistory = ({ user }) => {
    const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
    const [items, setItems] = useState([]);

    const [currentMonth, setCurrentMonth] = useState(moment().month() + 1);
    const [currentYear, setCurrentYear] = useState(moment().year());


    useEffect(() => {
        getHistory();
    }, [currentMonth, currentYear]);



    const getHistory = () => {
        setIsLoaderInfo(true);
        apiPost(
            ENDPOINTS.MoodHistory,
            {
                user_id: user?.id,
                month_name: currentMonth.toString(),
                year: currentYear.toString(),

            },
            (res) => {
                setItems(res?.data);
                setIsLoaderInfo(false);
            },
            (error) => {
                console.log(error);
                setIsLoaderInfo(false);
            }
        );
    };



    return (
        <>


            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <div className='w-300'>
                        <div className='mt-2'>
                            <h6>Select month</h6>
                            <Form.Select onChange={(e) => setCurrentMonth(e.target.value)} defaultValue={currentMonth} aria-label="Default select example">
                                <option value="1">January</option>
                                <option value="2">February</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </Form.Select>
                        </div>

                        <div className='mt-4'>
                            <h6>Select year</h6>
                            <Form.Select onChange={(e) => setCurrentYear(e.target.value)} defaultValue={currentYear}>
                                <option value="2024">2024</option>
                                <option value="2023">2023</option>
                                <option value="2022">2022</option>
                                <option value="2021">2021</option>
                                <option value="2020">2020</option>
                                <option value="2019">2019</option>
                                <option value="2018">2018</option>
                                <option value="2017">2017</option>
                                <option value="2016">2016</option>
                                <option value="2015">2015</option>
                                <option value="2014">2014</option>
                                <option value="2013">2013</option>
                                <option value="2012">2012</option>
                                <option value="2011">2011</option>
                                <option value="2010">2010</option>
                                <option value="2009">2009</option>
                                <option value="2008">2008</option>
                                <option value="2007">2007</option>
                                <option value="2006">2006</option>
                                <option value="2005">2005</option>
                            </Form.Select>

                        </div>


                    </div>
                </div>


            </div>


            <div className='row mt-4'>
                {items.length > 0 && items?.map((item, index) => (
                    <div className='col-lg-3 col-md-4 col-sm-6 mb-2' key={index} >
                        <MoodItem currentMood={item} />
                    </div>
                ))}

                {items.length == 0 && (
                    <h3 className='text-danger text-center'>Record not found</h3>
                )}
            </div>
        </>
    )
}

export default MoodHistory