import moment from 'moment';
import { useSpring } from 'react-spring';
export const CheckImageExtensionAll = (filename) => {
    const imgType = ['txt', 'docx', 'pdf', 'apng', 'avif', 'gif', 'jpg', 'jpeg', 'png', 'svg', 'webp', 'mp4', 'wewbm', 'ogg'];
    var fileType = filename['name'].split('.').pop();
    const acceptFiles = imgType.includes(fileType)
    return acceptFiles;
};



export const isDayWorking = (daysArray, item) => {
    if (daysArray?.length > 0) {
        return daysArray.includes(item)
    }
};


export const useCountAnimation = (initialValue, targetValue) => {
    const { count } = useSpring({
        from: { count: initialValue },
        count: targetValue,
        config: { duration: 2000 },
    });
    return count;
};

export const dateMonthYear = (date) => {
    return moment.utc(date).format('DD-MMM-YYYY')
};

export const dateTime = (date) => {
    return moment.utc(date).format('hh:mm')
};
