import React from "react";
import Modal from "react-bootstrap/Modal";
import { useRecoilState } from "recoil";
import { isLoaderState, snakeBarState } from "../../recoil/RecoilStore";
import { apiPost } from "../../util/ApiRequest";
import { ENDPOINTS } from "../../util/EndPoint";
import { CustomInput } from "../global/CustomInput";

const UpdateDisorders = ({
  showModalPro,
  setModalHidePro,
  refreshDataPro,
  selectItem,
}) => {
  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);
  const handleSubmit = (event) => {
    setIsLoaderInfo(true);
    event.preventDefault();
    const body = new FormData(event.target);
    apiPost(
      ENDPOINTS.UpdateSymptomsDisorders,
      body,
      (res) => {
        setModalHidePro();
        refreshDataPro();
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-success",
          snackMsg: "Successful",
        });
        setIsLoaderInfo(false);
      },
      (error) => {
        setIsLoaderInfo(false);
        setModalHidePro();
        refreshDataPro();
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: "There is an Error Plz Try Again ",
        });
      }
    );
  };

  return (
    <>
      <Modal show={showModalPro} onHide={() => setModalHidePro()}>
        <Modal.Header
          closeButton
          className="bg-primary text-white custom-modal-header"
        >
          <Modal.Title className="font-18">Update disorders</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <CustomInput
              label={"name"}
              name={"name"}
              type={"text"}
              required={true}
              defaultValue={selectItem?.name}
            />
            <input type="hidden" value={"disorders"} name="type" />
            <input
              type="hidden"
              value={selectItem?.id}
              name="symptoms_disorder_id"
            />
            <input className="btn btn-secondary mt-3" type="submit" />
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateDisorders;
